import Typography from '@mui/material/Typography';
import CustomContainer from '../CustomContainer/CustomContainer';
import Box from '@mui/material/Box';
import { buildApiUrl } from '../../../services/api';
import { useEffect, useState } from 'react';
import useLocale from '../../../CustomHooks/useLocale';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { LOCALE_MAP } from '../../../const';
import classes from './SegmentResults.module.css';

export async function fetchSegmentResults(segmentId: number) {
    try {
        const response = await fetch(buildApiUrl(`/segment-result/leaderboard/${segmentId}`), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch payment history');
        }

        const data: Result[] = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch payment history:', error);
        return [];
    }
}

type Result = {
    id: number;
    firstName: string;
    lastName?: string;
    averagePower?: number;
    time: number;
    resultDate: string;
    wattsPerKg: number;
};
function SegmentResults() {
    const { locale } = useLocale();
    const [results, setResults] = useState<Result[]>([]);

    const segmentId = 2;
    const loadResults = async () => {
        const data = await fetchSegmentResults(segmentId);
        setResults(data);
    };

    useEffect(() => {
        loadResults();
    }, [segmentId]);

    const formatTime = (timeInTenths: number): string => {
        const totalSeconds = timeInTenths / 10;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const segmentDistanceKm = 20.03; // Дистанция сегмента

    const formatSpeed = (timeInTenths: number): string => {
        if (timeInTenths === 0) return '-';
        const timeInHours = timeInTenths / (10 * 3600);
        const speedKmH = segmentDistanceKm / timeInHours;
        return speedKmH.toFixed(1); // Округляем до 1 знака после запятой
    };

    return (
        <section className={classes['segment-results']} aria-labelledby="segment-results_title">
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['segment-results__title']}
                    id="segment-results"
                >
                    Результаты заездов
                </Typography>
                <Box sx={{ width: '75%' }}>
                    <Typography variant="h3">Групповая гонка на Маяке</Typography>
                    <Typography variant="h4">Мужчины</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Место</TableCell>
                                <TableCell>Имя</TableCell>
                                <TableCell>Фамилия</TableCell>
                                <TableCell>Средняя мощность (Вт)</TableCell>
                                <TableCell>Ватт/кг</TableCell>
                                <TableCell>Время</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[
                                ["Михаил", "Кузнецов", "25:37.2", 308, "4,53"],
                                ["Евгений", "Рулевский", "25:37.6", 386, "4,54"],
                                ["Dimka", "Robopob", "25:37.9", 358, "5,11"],
                                ["Lev", "Zolotykh", "25:38.3", 315, "4,63"],
                                ["Иван", "Надоров", "28:34.6", 330, "3,8"],
                                ["Марат", "Кашапов", "28:40.5", 330, "3,79"],
                                ["Вадим", "Рытов", "28:41.5", 251, "3,64"],
                                ["Александр", "Воробьёв", "28:41.9", 244, "3,59"],
                                ["Denis", "Rotmans", "28:42.0", 271, "3,71"],
                                ["Юрий", "Ермолаев", "31:23.2", 255, "3,45"],
                                ["Николай", "Литунов", "33:24.5", 257, "3,06"],
                                ["Юрий", "Протонин", "33:25.5", 258, "3,19"],
                                ["Николай", "Никитин", "35:09.8", 258, "3,07"],
                                ["Сергей", "Филатов", "37:06.8", 255, "2,68"]
                            ].map(([firstName, lastName, time, avgWatts, kgsWatts], index) => (
                                <TableRow key={time}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{firstName}</TableCell>
                                    <TableCell>{lastName}</TableCell>
                                    <TableCell>{avgWatts}</TableCell>
                                    <TableCell>{kgsWatts}</TableCell>
                                    <TableCell>{time}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Typography mt={2} variant="h4">Девушки</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Место</TableCell>
                                <TableCell>Имя</TableCell>
                                <TableCell>Фамилия</TableCell>
                                <TableCell>Средняя мощность (Вт)</TableCell>
                                <TableCell>Ватт/кг</TableCell>
                                <TableCell>Время</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[
                                ["Дарья", "Чунарева", "28:50.3", 257, "4,21"],
                                ["Екатерина", "Олейник", "29:20.6", 227, "3,6"],
                                ["Nina", "Lisko", "29:21.2", 198, "3,41"],
                                ["Liza", "Shvetsova", "31:13.5", 207, "3,18"],
                                ["Надежда", "Мазнюк", "37:13.0", 159, "2,25"]
                            ].map(([firstName, lastName, time, avgWatts, kgsWatts], index) => (
                                <TableRow key={time}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{firstName}</TableCell>
                                    <TableCell>{lastName}</TableCell>
                                    <TableCell>{avgWatts}</TableCell>
                                    <TableCell>{kgsWatts}</TableCell>
                                    <TableCell>{time}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Typography variant="h3" mt={4}>Командные гонки</Typography>
                    <Typography variant="h4">Мужчины</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Место</TableCell>
                                <TableCell>Команда</TableCell>
                                <TableCell>Состав</TableCell>
                                <TableCell>Время</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            <TableRow>
                                <TableCell>1</TableCell>
                                <TableCell>Rullez 2</TableCell>
                                <TableCell>Никита Назаров 4,47 вт/кг, Danila Naumov 4,42 вт/кг, Ратников Анатолий</TableCell>
                                <TableCell>26:28.7</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>2</TableCell>
                                <TableCell>Rullez 1</TableCell>
                                <TableCell>Матвей Игумнов 4,14 вт/кг, Dimka Robopob 4,79 вт/кг, Евгений Рулевский 4,38 вт/кг</TableCell>
                                <TableCell>26:36.1</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>3</TableCell>
                                <TableCell>Ветерок</TableCell>
                                <TableCell>Lev Zolotykh 4,31 вт/кг, Михаил Кузнецов 4,13 вт/кг, Вадим Рытов 3,74 вт/кг</TableCell>
                                <TableCell>27:40.8</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>4</TableCell>
                                <TableCell>Rullez 3</TableCell>
                                <TableCell>Юрий Протонин 3,02 вт/кг, ILYA Nozhkin 3,03 вт/кг, Андрей Баратынский 2,97 вт/кг</TableCell>
                                <TableCell>32:57.1</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Typography mt={2} variant="h4">Девушки</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Место</TableCell>
                                <TableCell>Команда</TableCell>
                                <TableCell>Состав</TableCell>
                                <TableCell>Время</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            <TableRow>
                                <TableCell>1</TableCell>
                                <TableCell>Rullez W</TableCell>
                                <TableCell>Liza Shvetsova 2,94 вт/кг, Дарья Чунарева 3,39 вт/кг, Екатерина Олейник 3,14 вт/кг</TableCell>
                                <TableCell>31:30.3</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>2</TableCell>
                                <TableCell>ABSport W</TableCell>
                                <TableCell>Daria Gordeeva 2,76 вт/кг, Надежда Мазнюк 2,29 вт/кг, Kseniia Apollonova 1,87 вт/кг</TableCell>
                                <TableCell>46:46.7</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
                <Box mt={4} sx={{ width: '75%' }}>
                    <Typography variant="h3">Лучшие результаты на сегменте «Маяк»</Typography>
                    {results.length === 0 ? (
                        <Typography>Данные о результатах отсутствуют.</Typography>
                    ) : (
                        <>
                            <Table
                                sx={{
                                    display: { xs: 'block', sm: 'table' },
                                    overflowX: 'auto',
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>№</TableCell>
                                        <TableCell>Имя</TableCell>
                                        <TableCell>Фамилия</TableCell>
                                        <TableCell>Время</TableCell>
                                        <TableCell>Средняя мощность (Вт)</TableCell>
                                        <TableCell>Вт/Кг</TableCell>
                                        <TableCell>Средняя скорость (км/ч)</TableCell>
                                        <TableCell>Дата</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {results.map((result, index) => (
                                        <TableRow key={result.id}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{result.firstName}</TableCell>
                                            <TableCell>{result.lastName}</TableCell>
                                            <TableCell>{formatTime(result.time)}</TableCell>
                                            <TableCell>{result.averagePower || '-'}</TableCell>
                                            <TableCell>{result.wattsPerKg}</TableCell>
                                            <TableCell>{formatSpeed(result.time)}</TableCell>
                                            <TableCell>
                                                {new Date(result.resultDate).toLocaleDateString(LOCALE_MAP[locale] ?? "ru-RU")}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </Box>
            </CustomContainer>
        </section>
    );
}

export default SegmentResults;
